import React from "react";
import { StaticImage } from "gatsby-plugin-image"
// import BannerVideo from "../../images/llj_home_banner.jpg"
import loadable from "@loadable/component"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './assets/styles/_index.scss';
const Slider = loadable(() => import("react-slick"));

const HomeBannerImage = (props) => {
    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                },
            },
        ],
    }
    // Slider settings

    return (
        // <StaticImage src="../../images/llj_home_banner.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid banner-img" />
        <video
            src={"https://ggfx-lljluxury.s3.eu-west-2.amazonaws.com/i.prod/lljluxury_video_banner_a020afa821.mp4"}
            alt={`${process.env.GATSBY_SITE_NAME} - Home`}
            className="banner-img"
            autoPlay="autoplay"
            ref={props.videoRef}
            playsInLine="playsinline"
            loop="true"
            muted="true"
        />
        // <Slider className="home-banner-slider banner-img" {...settings}>
        //     <div className="home-banner-slide">
        //         <StaticImage src="../../images/home_slider_1.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid slide-img" />
        //     </div>
        //     <div className="home-banner-slide">
        //         <StaticImage src="../../images/home_slider_2.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid slide-img" />
        //     </div>
        //     <div className="home-banner-slide">
        //         <StaticImage src="../../images/home_slider_3.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid slide-img" />
        //     </div>
        //     <div className="home-banner-slide">
        //         <StaticImage src="../../images/home_slider_4.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid slide-img" />
        //     </div>
        //     <div className="home-banner-slide">
        //         <StaticImage src="../../images/home_slider_5.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid slide-img" />
        //     </div>
        // </Slider>
    )

}

export default HomeBannerImage